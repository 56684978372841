import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { API_CONFIG } from './api-config';

@Injectable()
export class ApiService {
  protected config = inject(API_CONFIG);
  protected client = inject(HttpClient);

  protected get url() {
    return this.config.url;
  }

  protected get headers() {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  }

  get<T>(
    path: string,
    params: {
      [param: string]:
        | string
        | number
        | boolean
        | readonly (string | number | boolean)[];
    } = {},
  ): Observable<T> {
    return this.client.get<T>(`${this.url}${path}`, {
      params: new HttpParams({ fromObject: params }),
      headers: this.headers,
    });
  }

  post<T>(path: string, body: Record<string, unknown> = {}): Observable<T> {
    return this.client.post<T>(`${this.url}${path}`, JSON.stringify(body), {
      headers: this.headers,
    });
  }

  put<T>(path: string, body: Record<string, unknown>): Observable<T> {
    return this.client.put<T>(`${this.url}${path}`, JSON.stringify(body), {
      headers: this.headers,
    });
  }

  delete<T>(path: string): Observable<T> {
    return this.client.delete<T>(`${this.url}${path}`, {
      headers: this.headers,
    });
  }
}
