import { provideHttpClient } from '@angular/common/http';
import { makeEnvironmentProviders } from '@angular/core';

import { API_CONFIG, ApiConfig } from './api-config';
import { ApiService } from './api.service';

export const provideApi = (config: ApiConfig) => {
  return makeEnvironmentProviders([
    provideHttpClient(),
    { provide: API_CONFIG, useValue: config },
    ApiService,
  ]);
};
