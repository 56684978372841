import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, withViewTransitions } from '@angular/router';

import { environment } from '@environment/environment';
import { provideApi } from '@features/api';

import { routes } from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideApi({ url: environment.api_url }),
    provideRouter(routes, withViewTransitions()),
  ],
};
